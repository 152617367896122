import { AboutSummary } from '@components/about-summary/about-summary';
import { PackagePricing } from '@components/detailed-pricing/PackagePricing';
import { FooterCategoriesV2 } from '@components/footer_categories/footer-categories-v2';
import { useIsStorefrontSearch } from '@components/listings-search-bar/hooks/use-is-storefront-search';
import { ListingsSearchBar } from '@components/listings-search-bar/listings-search-bar';
import { VenuePricing } from '@components/pricing/VenuePricing';
import WidgetAlgorithms from '@containers/widget/constants/algorithms';
import { FEATURE_FLAGS } from '@feature-flags/constants';
import { useStorefrontBookNow } from '@feature-flags/hooks/Storefront/useStorefrontBookNow';
import { useStorefrontBookingTest1 } from '@feature-flags/hooks/Storefront/useStorefrontBookingTest1';
import { useStorefrontBookingTest2 } from '@feature-flags/hooks/Storefront/useStorefrontBookingTest2';
import { useStorefrontCouplesUsuallySpend } from '@feature-flags/hooks/Storefront/useStorefrontCouplesUsuallySpend';
import { useStorefrontEmptyStates } from '@feature-flags/hooks/Storefront/useStorefrontEmptyStates';
import { useUiReadabilityEnhancements } from '@feature-flags/hooks/Storefront/useUiReadabilityEnhancements';
import { useUserEngagementInteractivity } from '@feature-flags/hooks/Storefront/useUserEngagementInteractivity';
import { useDetailedPricing } from '@hooks/use-detailed-pricing';
import { useIsVenue } from '@hooks/use-is-venue';
import { useLiteStorefront } from '@hooks/use-lite-storefront';
import { useStorefrontEstimatedPricingExperiment } from '@hooks/use-storefront-estimated-pricing-experiment';
import { reportServerSideExperiment } from '@redux/experiments';
import { useAppDispatch, useAppSelector } from '@redux/hooks';
import { setVendorReferredMarketCode } from '@redux/settings';
import { experiments } from '@settings';
import { Column, Row, TopLevelContainer } from '@xo-union/tk-component-grid';
import Spinner from '@xo-union/tk-component-spinner';
import { DisplayLink } from '@xo-union/tk-ui-links';
import classNames from 'classnames';
import { CookieStorage } from 'cookie-storage';
import { useAtomValue } from 'jotai';
import React, { useEffect, useMemo } from 'react';
import { StickyContainer } from 'react-sticky';
import type { Decorated, Raw } from 'types/vendor';
import { VENDOR_REFERRED_MARKET_CODE } from '../../../constants/cookies';
import { composeStorefrontCanonicalUrl } from '../../../helpers/seo';
import VendorHelper from '../../../helpers/vendor';
import ExitEarlyAlert from '../../components/auto-account-creation/exitEarlyAlert/ExitEarlyAlert';
import Breadcrumbs from '../../components/shared/Breadcrumbs';
import MetaContent from '../../components/shared/HelmetHeader/HelmetHeader';
import { Widget } from '../../containers/widget';
import { RecommendedContextProvider } from '../../contexts/RecommendedContext/RecommendedContext';
import { useStorefrontFeatureBvcPlusPricing } from '../../feature-flags/hooks/Storefront/useStorefrontFeatureBvcPlusPricing';
import { useShowAvailability } from '../../hooks/use-show-availability';
import { teamMembersAtom } from '../../jotai/storefront/organization';
import Styles from './Storefront.scss';
import AvailabilitySection from './components/AvailabilitySection/AvailabilitySection';
import { availabilityDataAtom } from './components/AvailabilitySection/availability-atoms';
import { BookNowContainer } from './components/BookNow/BookNowContainer';
import { LiteWidget } from './components/LiteWidget';
import PricingSection from './components/PricingSection/PricingSection';
import WhyUseKnotFullWidth from './components/WhyUseKnotFullWidth';
import CtaWrapper from './containers/CtaWrapper/CtaWrapper';
import StorefrontGallery from './containers/StorefrontGallery/StorefrontGallery';
import Bio from './containers/bio/Bio';
import { ContactLocation } from './containers/contact';
import { Details } from './containers/details/Details';
import DetailsHelper from './containers/details/helpers';
import { MeetTheTeam } from './containers/meet_the_team/meet_the_team';
import { Nav } from './containers/nav';
import Reviews from './containers/reviews/ReviewsContainer';
import TourLightbox from './containers/tour_lightbox';
import { VendorAbout } from './containers/vendor_about';
import { VendorHeader } from './containers/vendor_header/VendorHeader';
import { buildProperties } from './helpers';
import { useFetchingMember } from './hooks/use-fetching-member';
import { useGetConversions } from './hooks/use-get-conversations';
import { useOrderRecentlySeenVendors } from './hooks/use-order-recently-seen-vendors';
import { useStorefrontExperiments } from './hooks/use-storefront-experiments';
import { useStorefrontFeatureScannability } from './hooks/useStorefrontFeatureScannability';
import { useStorefrontFeatures } from './hooks/useStorefrontFeatures';
import { getStorefrontTitleExperiment } from './utils/renameStorefrontTitleExperiment';

const cookieStorage = new CookieStorage();

const Storefront = () => {
	const dispatch = useAppDispatch();
	const featureFlags = useStorefrontFeatures();
	const vendor = useAppSelector((state) => state.vendor.vendor as Decorated);
	const vendorRaw = useAppSelector((state) => state.vendor.vendorRaw as Raw);
	const viewport = useAppSelector((state) => state.viewport);
	const category = useAppSelector((state) => state.category);
	const memberId = useAppSelector((state) => state.membership.member?.id || '');
	const searchPageLocation = useAppSelector(
		(state) => state.searchPageLocation,
	);
	const crossCatLength = useAppSelector(
		(state) =>
			state.widget.pvRecCrossCatVendorsReducer.recommendedVendors.length,
	);
	const userEngagementInteractivityAssignmentVariant =
		useUserEngagementInteractivity();
	const isUIReadabilityEnhancementsVariant = useUiReadabilityEnhancements();
	const isVenue = useIsVenue();
	const ogImage = vendor.logo?.url;
	const pathname = vendor.uriData?.uri;
	const { state } = vendor.address;
	const { isMobileApp } = viewport;
	const isLiteStorefront = useLiteStorefront();
	const isDetailedPricing = useDetailedPricing();
	const isBvcPlusDetailedPricing = useStorefrontFeatureBvcPlusPricing();
	const isEmptyStates = useStorefrontEmptyStates();
	const availabilityData = useAtomValue(availabilityDataAtom);
	const isCusEnabled = useStorefrontCouplesUsuallySpend();
	const shouldShowAvailability = useShowAvailability();
	const isBookingTest1 = useStorefrontBookingTest1();
	const isBookingTest2 = useStorefrontBookingTest2();
	const isEstimatedPricing = useStorefrontEstimatedPricingExperiment();
	const isBookNow = useStorefrontBookNow();
	const isStorefrontSearch = useIsStorefrontSearch();
	useStorefrontExperiments();

	const isVendorBio = useMemo(() => {
		return featureFlags[FEATURE_FLAGS.UNIFIED_VENDOR_BIO];
	}, [featureFlags[FEATURE_FLAGS.UNIFIED_VENDOR_BIO]]);

	const isScannability = useMemo(() => {
		return featureFlags[FEATURE_FLAGS.STOREFRONT_SCANNABILITY];
	}, [featureFlags[FEATURE_FLAGS.STOREFRONT_SCANNABILITY]]);

	const properties = useMemo(
		() => buildProperties({ vendor, viewport, memberId, isBookNow }),
		[vendor, viewport, memberId, isBookNow],
	);

	const shouldHideBio = useMemo(() => {
		return !VendorHelper.bioHasContent(vendor.bio);
	}, [vendor.bio]);

	const hasTeamMembers = useAtomValue(teamMembersAtom).length > 0;

	const awards = useMemo(() => {
		const affiliates = vendorRaw.affiliates;
		if (affiliates.length) {
			return affiliates[0].affiliates.reduce<string[]>((acc, curr) => {
				if (curr.name) {
					acc.push(curr.name);
				}
				return acc;
			}, []);
		}

		return [];
	}, [vendorRaw.affiliates]);

	const shouldShowScannability = useStorefrontFeatureScannability();
	useGetConversions(cookieStorage);
	useOrderRecentlySeenVendors();
	useFetchingMember(properties);

	// biome-ignore lint/correctness/useExhaustiveDependencies: onMount
	useEffect(() => {
		cookieStorage.removeItem(VENDOR_REFERRED_MARKET_CODE);
		dispatch(setVendorReferredMarketCode(properties.vendorReferredMarketCode));
	}, []);

	// biome-ignore lint/correctness/useExhaustiveDependencies: onMount
	useEffect(() => {
		if (userEngagementInteractivityAssignmentVariant) {
			// biome-ignore lint/suspicious/noConsoleLog: Experiment logging
			console.log(
				'userEngagementInteractivityAssignment assignment: ',
				userEngagementInteractivityAssignmentVariant,
			);
			dispatch(
				reportServerSideExperiment(experiments.userEngagementInteractivity),
			);
		}
	}, [userEngagementInteractivityAssignmentVariant]);

	return (
		<RecommendedContextProvider>
			<TopLevelContainer
				id="storefront-v4"
				data-testid="storefront-v4"
				data-categorycode={category.code}
				data-purchase-status={vendor.purchaseStatus}
				data-lite-storefront={isLiteStorefront}
				data-scannability={shouldShowScannability}
				data-ui-readability-enhancements={isUIReadabilityEnhancementsVariant}
				data-detailed-pricing={isDetailedPricing}
				data-bvc-plus-detailed-pricing={isBvcPlusDetailedPricing}
				data-empty-states={isEmptyStates}
				data-usually-spend={isCusEnabled}
				data-booking-test-1={isBookingTest1}
				data-booking-test-2={isBookingTest2}
				data-estimated-pricing={isEstimatedPricing}
				data-book-now={isBookNow}
				data-is-storefront-search={isStorefrontSearch}
			>
				<Row>
					<Column xs="12" lg={isLiteStorefront ? '8' : '12'}>
						<TourLightbox />
						<MetaContent
							canonical={composeStorefrontCanonicalUrl(
								vendor.uriData.relativeUri,
							)}
							description={VendorHelper.metaDescription(vendor, category)}
							landingPage="storefront"
							ogImage={ogImage}
							pathname={pathname}
							title={getStorefrontTitleExperiment(
								vendor.name,
								category.name,
								vendor.displayId,
							)}
						/>
						<div className={Styles.overlay}>
							<Spinner />
						</div>
						{!isMobileApp && (
							<div className={Styles.storefrontContainer}>
								<ExitEarlyAlert isStorefrontPage />
								<div
									className={classNames({
										[Styles.breadcrumbsSearchBarContainer]: isStorefrontSearch,
									})}
								>
									<div
										className={classNames({
											[Styles.breadcrumbsSearchBar]: isStorefrontSearch,
										})}
									>
										<Breadcrumbs
											categoryCode={category.code}
											city={searchPageLocation.city}
											isHeader
											stateCode={searchPageLocation.stateCode}
											vendor={vendor}
										/>
									</div>
									{isStorefrontSearch && <ListingsSearchBar />}
								</div>
							</div>
						)}
						{!vendor.isPaid && !isLiteStorefront && (
							<Row hGutters={false}>
								<Widget
									className={Styles.topWidget}
									cardType="sm"
									algorithmType="SIMILAR"
									impressionType="Rec Module: Storefront - Top"
								/>
							</Row>
						)}
						<StorefrontGallery />
						<StickyContainer
							itemScope
							itemType="http://schema.org/LocalBusiness"
						>
							<Row>
								<Column
									xs="12"
									lg={isLiteStorefront ? '12' : '8'}
									xl={
										isLiteStorefront ? '11' : shouldShowScannability ? '8' : '7'
									}
									className={classNames({
										[Styles.inlineCentering]: !shouldShowScannability,
										[Styles.inlineCenteringFull]: shouldShowScannability,
									})}
								>
									<Nav vendor={vendor}>
										<VendorHeader
											awards={awards}
											className={Styles.navContainerPaddingNoPadding}
											vendor={vendor}
										/>
										{shouldShowScannability ? (
											<AboutSummary
												title="About"
												className={Styles.noDivider}
											/>
										) : (
											<VendorAbout
												className={Styles.navContainerPaddingNoPadding}
												title="About"
											/>
										)}
										{(isDetailedPricing || isBvcPlusDetailedPricing) &&
											!isLiteStorefront &&
											!isVenue && (
												<PackagePricing
													title="Pricing"
													className={classNames({
														[Styles.noDivider]: shouldShowScannability,
													})}
												/>
											)}
										{isVenue && !isLiteStorefront && (
											<VenuePricing
												title="Pricing"
												className={classNames({
													[Styles.noDivider]: shouldShowScannability,
												})}
											/>
										)}
										<Details
											shouldHide={!DetailsHelper.hasDetails(vendor)}
											title={
												vendor.categoryCode === 'REC' ? 'Amenities' : 'Details'
											}
											className={classNames({
												[Styles.noDivider]: shouldShowScannability,
											})}
										/>
										{isVenue ||
										isDetailedPricing ||
										isBvcPlusDetailedPricing ||
										isLiteStorefront ? null : (
											<PricingSection title="Pricing" />
										)}
										{shouldShowScannability &&
											isVendorBio &&
											hasTeamMembers && (
												<MeetTheTeam
													title="Team"
													className={Styles.noDivider}
												/>
											)}
										{shouldShowAvailability && (
											<AvailabilitySection
												title="Availability"
												availabilityData={availabilityData}
												className={Styles.noDivider}
											/>
										)}
										{!vendor.isPaid && (
											<div
												className={classNames(Styles.widgetWrapper, {
													[Styles.widgetHidden]: crossCatLength <= 0,
												})}
											>
												<Widget
													className={Styles.middleWidget}
													cardType={viewport.isMobile ? 'sm' : 'md'}
													algorithmType={WidgetAlgorithms.PVREC_CROSS_CAT}
													impressionType="Rec Module: Storefront - Center"
												/>
											</div>
										)}
										<Reviews
											title="Reviews"
											className={classNames({
												[Styles.noDivider]: shouldShowScannability,
											})}
										/>
										<ContactLocation title="Contact" />
										{!isScannability &&
											vendor.isPaid &&
											isVendorBio &&
											hasTeamMembers && <MeetTheTeam title="Team" />}
										{!isScannability && !isVendorBio && (
											<Bio
												shouldHide={shouldHideBio}
												title="Team"
												vendor={vendor}
											/>
										)}
									</Nav>
									{isLiteStorefront && (
										<LiteWidget
											className={Styles.liteWidgetMobile}
											algorithmType="BOW"
										/>
									)}
									{!isMobileApp && !shouldShowScannability && (
										<FooterCategoriesV2
											city={searchPageLocation.city || ''}
											stateCode={state}
										/>
									)}
								</Column>
								<Column xs="12" lg="4">
									{isBookNow ? <BookNowContainer /> : <CtaWrapper />}
								</Column>
							</Row>
						</StickyContainer>
						{!isMobileApp && shouldShowScannability && (
							<FooterCategoriesV2
								city={searchPageLocation.city || ''}
								stateCode={state}
							/>
						)}
						{!isMobileApp && (
							<div className={Styles.footerCategoriesBreadcrumbContainer}>
								<Breadcrumbs
									categoryCode={category.code}
									city={searchPageLocation.city}
									stateCode={searchPageLocation.stateCode}
									vendor={vendor}
								/>
							</div>
						)}
						<DisplayLink
							href={`${vendor.uriData.relativeUri}/photos`}
							color="secondary"
							className={Styles.photosLink}
						>
							{vendor.name}'s photos
						</DisplayLink>
						{shouldShowScannability && <WhyUseKnotFullWidth />}
					</Column>
					{isLiteStorefront && (
						<Column className={Styles.liteWidget} lg="4">
							<LiteWidget algorithmType="SIMILAR" />
						</Column>
					)}
				</Row>
			</TopLevelContainer>
		</RecommendedContextProvider>
	);
};

export default Storefront;
